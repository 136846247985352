var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "status" }, [
    _c("div", [
      _c("span", [_vm._v("Status:")]),
      _c("span", [
        _vm._v(_vm._s(_vm._f("jobStatusFormat")(_vm.jobData.status))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }